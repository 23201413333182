import(/* webpackMode: "eager", webpackExports: ["ContextProviders"] */ "/vercel/path0/components/_stores/_context-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadInitialData"] */ "/vercel/path0/components/_stores/_load-initial-data.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/vercel/path0/components/header/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/vercel/path0/components/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/style/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/style/prism.scss");
